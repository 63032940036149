import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import { selectedCompanyIdSelector, allCompanyOptionsSelector } from 'src/store/selectors/companySelector';
import { selectCompany } from 'src/store/slices/companySlice';

import Select, { SingleValue } from '@itm/shared-frontend/lib/components/react-select';
import { dropdownFilterOption } from '@itm/shared-frontend/lib/utils';

import { SelectOption } from 'src/types';

function CompanyFilter() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const companyOptions = useSelector(allCompanyOptionsSelector);

  const isFilterAvailable = useMemo(
    () =>
      Boolean(
        matchPath(RoutePath.configurationParametersList, pathname) ||
          matchPath(RoutePath.configurationValidationsList, pathname) ||
          matchPath(RoutePath.configurationBulkUploadHistoryList, pathname) ||
          matchPath(RoutePath.configurationBulkUploadUploadValidation, pathname) ||
          matchPath(RoutePath.configurationScoringDefinitionScoreTypeList, pathname) ||
          matchPath(RoutePath.configurationScoringDefinitionDataItemList, pathname) ||
          matchPath(RoutePath.configurationScoringDefinitionValidationGroupingList, pathname) ||
          matchPath(RoutePath.dataManagementUploadHistoryList, pathname) ||
          matchPath(RoutePath.reportingTemplatesList, pathname),
      ),
    [pathname],
  );

  const selectedCompanyOption = useMemo(
    () => companyOptions.find((option) => option.value === selectedCompanyId) || null,
    [companyOptions, selectedCompanyId],
  );

  const companyChangeHandler = (newOption: SingleValue<SelectOption>) => {
    if (newOption) {
      dispatch(selectCompany(newOption.value));
    }
  };

  return (
    <div className="is-flex is-justify-content-center pt-5">
      <div className="control is-fullwidth">
        <Select
          className="react-select"
          classNamePrefix="react-select"
          value={selectedCompanyOption}
          options={companyOptions}
          aria-label="Company name"
          placeholder={companyOptions.length ? 'Company Name' : 'No companies'}
          onChange={companyChangeHandler}
          isDisabled={!isFilterAvailable || !companyOptions.length}
          isClearable={false}
          isSearchable={true}
          closeMenuOnSelect={true}
          filterOption={dropdownFilterOption}
        />
      </div>
    </div>
  );
}

export default CompanyFilter;
