export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',

  // Configuration
  configurationRoot: '/configuration',

  // Configuration Parameters
  configurationParametersRoot: '/configuration/parameters',
  configurationParametersList: '/configuration/parameters/list',

  configurationParameterEditRoot: '/configuration/parameters/:schemeId/edit',
  configurationParameterEditDetails: '/configuration/parameters/:schemeId/edit/details',

  configurationParameterEditSectionsRoot: '/configuration/parameters/:schemeId/edit/sections',
  configurationParameterEditSectionsList: '/configuration/parameters/:schemeId/edit/sections/list',

  configurationParameterEditSectionRoot: '/configuration/parameters/:schemeId/edit/sections/:sectionId',
  configurationParameterEditSectionDetails: '/configuration/parameters/:schemeId/edit/sections/:sectionId/details',
  configurationParameterEditSectionTranchRoot: '/configuration/parameters/:schemeId/edit/sections/:sectionId/tranch',
  configurationParameterEditSectionTranchUpload:
    '/configuration/parameters/:schemeId/edit/sections/:sectionId/tranch/upload-data',
  configurationParameterEditSectionTranchEdit:
    '/configuration/parameters/:schemeId/edit/sections/:sectionId/tranch/:tranchId/edit',

  configurationParameterEditPensionElementRoot: '/configuration/parameters/:schemeId/edit/elements',
  configurationParameterEditPensionElementList: '/configuration/parameters/:schemeId/edit/elements/list',
  configurationParameterEditPensionElementCreate: '/configuration/parameters/:schemeId/edit/elements/create',
  configurationParameterEditPensionElementEdit: '/configuration/parameters/:schemeId/edit/elements/:elementId/edit',

  // Configuration Validations
  configurationValidationsRoot: '/configuration/validations',
  configurationValidationsList: '/configuration/validations/list',
  configurationValidationViewRoot: '/configuration/validations/:validationId/view',
  configurationValidationViewDetails: '/configuration/validations/:validationId/view/details',
  configurationValidationEditRoot: '/configuration/validations/:validationId/edit',
  configurationValidationEditDetails: '/configuration/validations/:validationId/edit/details',
  configurationScoringDefinitionRoot: '/configuration/scoring-definition',
  configurationScoringDefinitionScoreTypeRoot: '/configuration/scoring-definition/score-type',
  configurationScoringDefinitionScoreTypeList: '/configuration/scoring-definition/score-type/list',
  configurationScoringDefinitionScoreTypeCreate: '/configuration/scoring-definition/score-type/create',
  configurationScoringDefinitionScoreTypeEdit: '/configuration/scoring-definition/score-type/:scoreTypeId/edit',
  configurationScoringDefinitionDataItemRoot: '/configuration/scoring-definition/data-item',
  configurationScoringDefinitionDataItemList: '/configuration/scoring-definition/data-item/list',
  configurationScoringDefinitionDataItemCreate: '/configuration/scoring-definition/data-item/create',
  configurationScoringDefinitionDataItemEdit: '/configuration/scoring-definition/data-item/:dataItemId/edit',
  configurationScoringDefinitionValidationGroupingRoot: '/configuration/scoring-definition/validation-grouping',
  configurationScoringDefinitionValidationGroupingList: '/configuration/scoring-definition/validation-grouping/list',
  configurationScoringDefinitionValidationGroupingBulkUpdate:
    '/configuration/scoring-definition/validation-grouping/file',

  //Configuration Bulk uploads
  configurationBulkUploadsRoot: '/configuration/bulk-uploads',

  configurationBulkUploadHistoryRoot: '/configuration/bulk-uploads/upload-history',
  configurationBulkUploadHistoryList: '/configuration/bulk-uploads/upload-history/list',

  configurationBulkUploadHistoryDetailsViewRoot: '/configuration/bulk-uploads/upload-history/file/:fileId/view',
  configurationBulkUploadHistoryDetailsViewUploadSummary:
    '/configuration/bulk-uploads/upload-history/file/:fileId/view/upload-summary',

  configurationBulkUploadUploadValidation: '/configuration/bulk-uploads/upload-validation',

  // Data management
  dataManagementRoot: '/data-management',
  dataManagementUploadHistoryRoot: '/data-management/upload-history',
  dataManagementUploadHistoryList: '/data-management/upload-history/list',
  dataManagementUploadHistoryFileViewRoot: '/data-management/upload-history/file/:fileId/view',
  dataManagementUploadHistoryFileViewUploadSummary: '/data-management/upload-history/file/:fileId/view/upload-summary',
  dataManagementAnalysisHistoryRoot: '/data-management/upload-history/file/:fileId/view/analysis-history',
  dataManagementAnalysisHistoryList: '/data-management/upload-history/file/:fileId/view/analysis-history/list',
  dataManagementAnalysisHistoryRunRoot:
    '/data-management/upload-history/file/:fileId/view/analysis-history/:validationRunId',
  dataManagementAnalysisHistoryRunDetails:
    '/data-management/upload-history/file/:fileId/view/analysis-history/:validationRunId/details',
  dataManagementAnalysisHistoryRunScoringSummaryDetails:
    '/data-management/upload-history/file/:fileId/view/analysis-history/:validationRunId/scoring/:scoreTypeId/details',
  dataManagementAnalysisHistoryRunDataItemScoringDetails:
    '/data-management/upload-history/file/:fileId/view/analysis-history/:validationRunId/scoring/:scoreTypeId/dataItem/:dataItemId/details',

  // Reporting
  reportingRoot: '/reporting',
  reportingTemplatesRoot: '/reporting/templates',
  reportingTemplatesList: '/reporting/templates/list',
  // Create Template
  reportingTemplateCreateRoot: '/reporting/templates/create',
  reportingTemplateCreateDetails: '/reporting/templates/create/details',
  // Edit Template
  reportingTemplateEditRoot: '/reporting/templates/edit/:templateId',
  reportingTemplateEditDetails: '/reporting/templates/edit/:templateId/details',
  reportingTemplateEditContentRoot: '/reporting/templates/edit/:templateId/content',
  reportingTemplateEditContentUpdate: '/reporting/templates/edit/:templateId/content/update',
  reportingTemplateEditHistoryRoot: '/reporting/templates/edit/:templateId/history',
  reportingTemplateEditHistoryList: '/reporting/templates/edit/:templateId/history/list',
  // View Template
  reportingTemplateViewRoot: '/reporting/templates/view/:templateId',
  reportingTemplateViewDetails: '/reporting/templates/view/:templateId/details',
  reportingTemplateViewContentRoot: '/reporting/templates/view/:templateId/content',
  reportingTemplateViewContentUpdate: '/reporting/templates/view/:templateId/content/update',
  reportingTemplateViewHistoryRoot: '/reporting/templates/view/:templateId/history',
  reportingTemplateViewHistoryList: '/reporting/templates/view/:templateId/history/list',
  // History
  reportingHistoryRoot: '/reporting/histories',
  reportingHistoryList: '/reporting/histories/list',

  // Product Information
  productInfo: '/product-info',
};

export { GuardedRoute } from './GuardedRoute';
