import { createRoot } from 'react-dom/client';
import App from './App.tsx';

import 'regenerator-runtime/runtime';
import 'src/assets/styles/index.scss';
import 'src/config/yup.config';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
