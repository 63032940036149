import { Converter } from '@itm/shared-frontend/lib/utils';
import getEnvVariable from './getEnvVariable';

import {
  TargetPlatform,
  ParameterStatus,
  UploadStatus,
  AnalysisStatus,
  SetupStatus,
  UploadHistoryStatus,
  ValidationType,
  TemplateStatus,
  TemplateType,
  ReportStatus,
  ValidationRunType,
  ValidationStatus,
} from 'src/types';

export const ENV_PREFIX = 'REACT_APP_' as const;

export const ENVIRONMENT = getEnvVariable('ENVIRONMENT');
export const TARGET_PLATFORM = getEnvVariable('TARGET_PLATFORM');
export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const DATA_AUDIT_API_URL = getEnvVariable('DATA_AUDIT_API_URL');
export const CLIENT_PORTAL_API_URL = getEnvVariable('CLIENT_PORTAL_API_URL');

export const isProductionEnv = ENVIRONMENT === 'PROD';
export const isAzureTargetPlatform = TARGET_PLATFORM === TargetPlatform.AZURE;

export const parameterStatusOptions = Converter.enumToSelectOptions(ParameterStatus);
export const uploadStatusOptions = Converter.enumToSelectOptions(UploadStatus);
export const analysisStatusOptions = Converter.enumToSelectOptions(AnalysisStatus);
export const benefitSectionSetupStatusOptions = Converter.enumToSelectOptions(SetupStatus);
export const uploadHistoryStatusOptions = Converter.enumToSelectOptions(UploadHistoryStatus);
export const validationTypeOptions = Converter.enumToSelectOptions(ValidationType);
export const templateStatusOptions = Converter.enumToSelectOptions(TemplateStatus);
export const templateTypeOptions = Converter.enumToSelectOptions(TemplateType);
export const reportStatusOptions = Converter.enumToSelectOptions(ReportStatus);
export const analysisRunTypeOptions = Converter.enumToSelectOptions(ValidationRunType);
export const analysisRunStatusOptions = Converter.enumToSelectOptions(ValidationStatus);
