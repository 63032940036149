import { useEffect, useMemo, useState } from 'react';
import { useLocation, matchPath, PathMatch, generatePath } from 'react-router-dom';
import { RoutePath as rp } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import { setBreadcrumbsNames } from 'src/store/slices/breadcrumbsSlice';
import { breadcrumbsNamesMapSelector } from 'src/store/selectors/breadcrumbsSelector';

import { Breadcrumb, Breadcrumbs } from '@itm/shared-frontend/lib/components';

type BreadcrumbConfig = {
  name: string | null | undefined;
  parentPath: string | null;
};

type ExtendedBreadcrumbsConfig = BreadcrumbConfig & Pick<PathMatch<string>, 'pathname'>;

type BreadcrumbConfigMap = {
  [key: string]: BreadcrumbConfig;
};

type UseSetBreadcrumbsNamesProps = Readonly<{
  routePath: string;
  value: string | null | undefined;
  isClearOnUnmount?: boolean;
}>;

export const useSetBreadcrumbsNames = ({ routePath, value, isClearOnUnmount = true }: UseSetBreadcrumbsNamesProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!value) return;
    dispatch(setBreadcrumbsNames({ [routePath]: value }));
  }, [routePath, value, dispatch]);

  useEffect(
    () => () => {
      if (!isClearOnUnmount) return;
      dispatch(setBreadcrumbsNames({ [routePath]: null }));
    },
    [routePath, isClearOnUnmount, dispatch],
  );
};

const getBreadcrumbs = (pathToMatch: string, breadcrumbsConfig: BreadcrumbConfigMap): Breadcrumb[] => {
  const matchedList = Object.keys(breadcrumbsConfig).reduce<PathMatch<string>[]>((acc, route) => {
    const matched = matchPath(route, pathToMatch);
    return matched ? [...acc, matched] : acc;
  }, []);

  if (!matchedList.length) return [];

  const pathMatch =
    // try to find the full match
    matchedList.find((m) => !m.pattern.path.endsWith('/*')) ||
    // if not found, find the longest wildcard match
    matchedList.toSorted((a, b) => b.pattern.path.length - a.pattern.path.length)[0];

  const routeParams = pathMatch.params;

  let revertedBreadcrumbsList: Breadcrumb[] = [];
  let extendedConfig: ExtendedBreadcrumbsConfig | null = {
    pathname: pathMatch.pathname,
    ...breadcrumbsConfig[pathMatch.pattern.path],
  };

  while (extendedConfig) {
    const { name, pathname, parentPath }: ExtendedBreadcrumbsConfig = extendedConfig;

    if (name) {
      revertedBreadcrumbsList.push({ name, to: pathname });
    } else {
      revertedBreadcrumbsList = [];
    }

    extendedConfig = parentPath
      ? { ...breadcrumbsConfig[parentPath], pathname: generatePath(parentPath, routeParams) }
      : null;
  }
  return revertedBreadcrumbsList.reverse();
};

function BreadcrumbsTrail() {
  const { pathname } = useLocation();
  const bcNamesMap = useSelector(breadcrumbsNamesMapSelector);
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const isHidden = useMemo(
    () =>
      Boolean(
        matchPath(`${rp.root}/*`, pathname) ||
          matchPath(`${rp.loginRedirect}/*`, pathname) ||
          matchPath(rp.permissionDenied, pathname),
      ),
    [pathname],
  );

  const config: BreadcrumbConfigMap = useMemo(
    () => ({
      [rp.root]: { name: 'Data Audit', parentPath: null },

      // CONFIGURATION
      [rp.configurationRoot]: { name: 'Configuration', parentPath: rp.root },

      // Configuration / Parameters
      [rp.configurationParametersList]: { name: 'All Parameters', parentPath: rp.configurationRoot },
      // Configuration / Parameters / Edit
      [rp.configurationParameterEditRoot]: {
        name: bcNamesMap[rp.configurationParameterEditRoot],
        parentPath: rp.configurationParametersList,
      },
      [rp.configurationParameterEditDetails]: { name: 'Scheme Details', parentPath: rp.configurationParameterEditRoot },
      // Configuration / Parameters / Edit / Sections
      [rp.configurationParameterEditSectionsList]: {
        name: 'Benefit Sections',
        parentPath: rp.configurationParameterEditRoot,
      },
      [rp.configurationParameterEditSectionDetails]: {
        name: bcNamesMap[rp.configurationParameterEditSectionDetails],
        parentPath: rp.configurationParameterEditSectionsList,
      },
      [rp.configurationParameterEditSectionsList]: {
        name: 'Benefit Sections',
        parentPath: rp.configurationParameterEditRoot,
      },
      [rp.configurationParameterEditSectionTranchUpload]: {
        name: 'Add New Tranches',
        parentPath: rp.configurationParameterEditSectionDetails,
      },
      [rp.configurationParameterEditSectionTranchEdit]: {
        name: bcNamesMap[rp.configurationParameterEditSectionTranchEdit],
        parentPath: rp.configurationParameterEditSectionDetails,
      },
      // Configuration / Parameters / Edit / Pension Elements
      [rp.configurationParameterEditPensionElementList]: {
        name: 'Pension Elements',
        parentPath: rp.configurationParameterEditRoot,
      },
      [rp.configurationParameterEditPensionElementCreate]: {
        name: 'Add Pension Element',
        parentPath: rp.configurationParameterEditPensionElementList,
      },
      [rp.configurationParameterEditPensionElementEdit]: {
        name: bcNamesMap[rp.configurationParameterEditPensionElementEdit],
        parentPath: rp.configurationParameterEditPensionElementList,
      },

      // Configuration / Validations
      [rp.configurationValidationsList]: { name: 'All Validations', parentPath: rp.configurationRoot },
      // Configuration / Validations / View
      [rp.configurationValidationViewRoot]: {
        name: bcNamesMap[rp.configurationValidationViewRoot],
        parentPath: rp.configurationValidationsList,
      },
      [rp.configurationValidationViewDetails]: {
        name: 'Validation Detail',
        parentPath: rp.configurationValidationViewRoot,
      },
      // Configuration / Validations / Edit
      [rp.configurationValidationEditRoot]: {
        name: bcNamesMap[rp.configurationValidationEditRoot],
        parentPath: rp.configurationValidationsList,
      },
      [rp.configurationValidationEditDetails]: {
        name: 'Validation Detail',
        parentPath: rp.configurationValidationEditRoot,
      },

      // Configuration / Bulk Uploads
      [rp.configurationBulkUploadsRoot]: { name: 'Bulk Uploads ', parentPath: rp.configurationRoot },
      [rp.configurationBulkUploadUploadValidation]: {
        name: 'Upload Validations',
        parentPath: rp.configurationBulkUploadsRoot,
      },
      [rp.configurationBulkUploadHistoryList]: {
        name: 'Upload History',
        parentPath: rp.configurationBulkUploadsRoot,
      },
      [`${rp.configurationBulkUploadHistoryDetailsViewRoot}/*`]: {
        name: bcNamesMap[rp.configurationBulkUploadHistoryDetailsViewRoot],
        parentPath: rp.configurationBulkUploadHistoryList,
      },

      // Configuration / Scoring Definition
      [rp.configurationScoringDefinitionRoot]: { name: 'Scoring Definition', parentPath: rp.configurationRoot },
      // Configuration / Scoring Definition / Score Types
      [rp.configurationScoringDefinitionScoreTypeList]: {
        name: 'Score Types',
        parentPath: rp.configurationScoringDefinitionRoot,
      },
      [rp.configurationScoringDefinitionScoreTypeCreate]: {
        name: 'Create Score Type',
        parentPath: rp.configurationScoringDefinitionRoot,
      },
      [rp.configurationScoringDefinitionScoreTypeEdit]: {
        name: 'Edit Score Type',
        parentPath: rp.configurationScoringDefinitionRoot,
      },
      // Configuration / Scoring Definition / Data Items
      [rp.configurationScoringDefinitionDataItemList]: {
        name: 'Data Items',
        parentPath: rp.configurationScoringDefinitionRoot,
      },
      [rp.configurationScoringDefinitionDataItemCreate]: {
        name: 'Create Data Item',
        parentPath: rp.configurationScoringDefinitionRoot,
      },
      [rp.configurationScoringDefinitionDataItemEdit]: {
        name: 'Edit Data Item',
        parentPath: rp.configurationScoringDefinitionRoot,
      },
      // Configuration / Scoring Definition / Validation Grouping
      [rp.configurationScoringDefinitionValidationGroupingList]: {
        name: 'Validation Grouping',
        parentPath: rp.configurationScoringDefinitionRoot,
      },
      [rp.configurationScoringDefinitionValidationGroupingBulkUpdate]: {
        name: 'Bulk Update',
        parentPath: rp.configurationScoringDefinitionValidationGroupingList,
      },

      // DATA MANAGEMENT
      [rp.dataManagementRoot]: { name: 'Data Management', parentPath: rp.root },

      // Data Management / Upload History
      [rp.dataManagementUploadHistoryList]: { name: 'Data Upload History', parentPath: rp.dataManagementRoot },
      [rp.dataManagementUploadHistoryFileViewRoot]: {
        name: bcNamesMap[rp.dataManagementUploadHistoryFileViewRoot],
        parentPath: rp.dataManagementUploadHistoryList,
      },
      [rp.dataManagementUploadHistoryFileViewUploadSummary]: {
        name: 'Upload Summary',
        parentPath: rp.dataManagementUploadHistoryFileViewRoot,
      },
      [rp.dataManagementAnalysisHistoryList]: {
        name: 'Analysis History',
        parentPath: rp.dataManagementUploadHistoryFileViewRoot,
      },
      [`${rp.dataManagementAnalysisHistoryRunRoot}/*`]: {
        name: bcNamesMap[rp.dataManagementAnalysisHistoryRunRoot],
        parentPath: rp.dataManagementAnalysisHistoryList,
      },

      // REPORTING
      [rp.reportingRoot]: { name: 'Reporting', parentPath: rp.root },

      // Reporting / Templates
      [`${rp.reportingTemplateCreateRoot}/*`]: { name: 'Add new template', parentPath: rp.reportingTemplatesList },
      [rp.reportingTemplatesList]: { name: 'All Templates', parentPath: rp.reportingRoot },
      // Reporting / Templates / View
      [rp.reportingTemplateViewRoot]: {
        name: bcNamesMap[rp.reportingTemplateViewRoot],
        parentPath: rp.reportingTemplatesList,
      },
      [rp.reportingTemplateViewDetails]: { name: 'General Details', parentPath: rp.reportingTemplateViewRoot },
      [rp.reportingTemplateViewContentUpdate]: { name: 'Template Content', parentPath: rp.reportingTemplateViewRoot },
      [rp.reportingTemplateViewHistoryList]: { name: 'Template History', parentPath: rp.reportingTemplateViewRoot },
      // Reporting / Templates / Edit
      [rp.reportingTemplateEditRoot]: {
        name: bcNamesMap[rp.reportingTemplateEditRoot],
        parentPath: rp.reportingTemplatesList,
      },
      [rp.reportingTemplateEditDetails]: { name: 'General Details', parentPath: rp.reportingTemplateEditRoot },
      [rp.reportingTemplateEditContentUpdate]: { name: 'Template Content', parentPath: rp.reportingTemplateEditRoot },
      [rp.reportingTemplateEditHistoryList]: { name: 'Template History', parentPath: rp.reportingTemplateEditRoot },

      // Reporting / Report History
      [rp.reportingHistoryList]: { name: 'Report History', parentPath: rp.reportingRoot },

      // PRODUCT INFO
      [rp.productInfo]: { name: 'Product Information', parentPath: rp.root },
    }),
    [bcNamesMap],
  );

  // Debounce breadcrumbs update to avoid unnecessary updates by redirects
  useEffect(() => {
    if (isHidden) return;

    const delayTimeout = setTimeout(() => {
      setBreadcrumbs(getBreadcrumbs(pathname, config));
    }, 50);
    return () => {
      clearTimeout(delayTimeout);
    };
  }, [config, isHidden, pathname]);

  return isHidden ? null : <Breadcrumbs breadcrumbs={breadcrumbs} />;
}

export default BreadcrumbsTrail;
